import React, { useState } from 'react'
import './qualification.css'

const Qualification = () => {
  const [toggleState, setToggleState] = useState(1)

  const toggleTab = (index) => {
    setToggleState(index)
  }

  return (
    <section className="qualification section">
      <h2 className="section__title">Qualification</h2>
      <span className="section__subtitle">My Personal Journey</span>

      <div className="qualification__container container">
        {/* */}
        <div className="qualification__tabs">
          {/* Education */}
          <div
            className={
              toggleState === 0
                ? 'qualification__button qualification__active button--flex'
                : 'qualification__button button--flex'
            }
            onClick={() => toggleTab(0)}
          >
            <i className="uil uil-graduation-cap qualification__icon"></i>
            Education
          </div>
          {/* Experience */}
          <div
            className={
              toggleState === 1
                ? 'qualification__button qualification__active button--flex'
                : 'qualification__button button--flex'
            }
            onClick={() => toggleTab(1)}
          >
            <i className="uil uil-briefcase-alt qualification__icon"></i>
            Experience
          </div>
        </div>
        <div className="qualification__sections">
          <div
            className={
              toggleState === 0
                ? 'qualification__content qualification__content-active'
                : 'qualification__content'
            }
          >
            <div className="qualification__data">
              <div>
                <h3 className="qualification__title">B.Sc in SWE</h3>
                <span className="qualification__subtitle">
                  Islamic University of Technology
                </span>
                <div className="qualification__calender">
                  <i className="uil uil-calendar-alt"></i> 2018 - 2022
                </div>
              </div>
              <div>
                <span className="qualification__rounder"></span>
                <div className="qualification__line"></div>
              </div>
            </div>
            {/*        <div className='qualification__data'>
              <div></div>
              <div>
                <span className='qualification__rounder'></span>
                <div className='qualification__line'></div>
              </div>
              <div>
                <h3 className='qualification__title'>M.Sc in SWE</h3>
                <span className='qualification__subtitle'>X University</span>
                <div className='qualification__calender'>
                  <i className='uil uil-calender-alt'></i>2024 - Indefinite
                </div>
              </div>
          </div>*/}
          </div>
          <div
            className={
              toggleState === 1
                ? 'qualification__content qualification__content-active'
                : 'qualification__content'
            }
          >
            <div className="qualification__data">
              <div></div>
              <div>
                <span className="qualification__rounder"></span>
                <div className="qualification__line"></div>
              </div>
              <div>
                <h3 className="qualification__title">Regional Winner</h3>
                <span className="qualification__subtitle">
                  bdapps National Hackathon
                </span>
                <div className="qualification__calender">
                  <i className="uil uil-calendar-alt"></i> 2022
                </div>
              </div>
            </div>
            <div className="qualification__data">
              <div>
                <h3 className="qualification__title">Web Developer Intern</h3>
                <span className="qualification__subtitle">
                  Genesys System LLC
                </span>
                <div className="qualification__calender">
                  <i className="uil uil-calendar-alt"></i> 2023
                </div>
              </div>

              <div>
                <span className="qualification__rounder"></span>
                <div className="qualification__line"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Qualification
