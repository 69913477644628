import React from 'react';
import './footer.css';

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='footer__container container'>
        <h1 className='footer__title'>Nafis</h1>
        <ul className='footer__list'>
          <li>
            <a href='#about' className='footer__link'>
              About
            </a>
          </li>
          <li>
            <a href='#portfolio' className='footer__link'>
              Projects
            </a>
          </li>
          <li>
            <a href='#services' className='footer__link'>
              Services
            </a>
          </li>
        </ul>
        <div className='footer__social'>
          <a
            draggable={false}
            href='https://github.com/nafisahmed42018'
            className='footer__social-link'
            target='_blank'
            //
            rel='noreferrer'
          >
            <i className='bx bxl-github'></i>
          </a>

          <a
            draggable={false}
            href='https://github.com/nafisahmed42018'
            className='footer__social-link'
            target='_blank'
            rel='noreferrer'
          >
            <i className='bx bxl-linkedin'></i>
          </a>

          <a
            draggable={false}
            href='https://github.com/nafisahmed42018'
            className='footer__social-link'
            target='_blank'
            rel='noreferrer'
          >
            <i className='bx bxl-blogger'></i>
          </a>
        </div>
        <span className='footer__copy'>
          &#169; Deprecated Engineer. All rights reserved.
        </span>
      </div>
    </footer>
  );
};

export default Footer;
