import React from 'react';
import './about.css';
import AboutImg from '../../assets/image_me.png';
import CV from '../../assets/Nafis_Resume.pdf';
import Info from './Info';
import Files from '../../assets/files.svg';

const About = () => {
  return (
    <section className='about section' id='about'>
      <h2 className='section__title'>About Me</h2>
      <span className='section__subtitle'>Introduction</span>

      <div className='about__container container grid'>
        <img src={AboutImg} alt='' className='about__img' draggable={false} />
        <div className='about__data'>
          <Info />
          <p className='about__description'>
            Frontend developer, I create webpages with UI/UX interface, just a
            hatchling with less than a year experience; Though people who know
            me doesn't dislike my works and i focus my time and effort on
            sharpening my current set of skill sets and add others in my arsenal
          </p>

          <a
            draggable={false}
            download=''
            href={CV}
            className='button button--flex'
          >
            Download CV
            <img src={Files} alt='' className='about__button-img' />
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
