import React, { useState, useEffect } from 'react';
import { projectData, projectType } from './Data';
import ProjectItems from './ProjectItems';
const Projects = () => {
  const [type, setType] = useState({ name: 'All' });
  const [projects, setProjects] = useState([]);
  const [active, setActive] = useState(0);

  useEffect(() => {
    if (type.name === 'All') {
      setProjects(projectData);
    } else {
      const filteredProjects = projectData.filter(project => {
        return project.type === type.name;
      });
      setProjects(filteredProjects);
    }
  }, [type]);

  const handleClick = (e, index) => {
    setType({ name: e.target.textContent });
    setActive(index);
  };

  return (
    <div>
      <div className='project__filters'>
        {projectType.map((item, index) => {
          return (
            <span
              className={`${
                active === index ? 'active-type' : ''
              } project__type`}
              key={index}
              onClick={e => {
                handleClick(e, index);
              }}
            >
              {item.name}
            </span>
          );
        })}
      </div>
      <div className='project__container container grid'>
        {projects.map(item => {
          return <ProjectItems project={item} key={item.id} />;
        })}
      </div>
    </div>
  );
};

export default Projects;
