import React from 'react';
// import Send from '../../assets/send.svg';
const Data = () => {
  return (
    <div className='home__data'>
      <h1 className='home__title'>Nafis Ahmed</h1>
      <h3 className='home__subtitle'>Software Engineer</h3>
      <p className='home__description'>
        I'm a software developer based in Dhaka, Bangladesh; I'm impassioned and
        devoted to my work.{' '}
      </p>

      <button
        draggable={false}
        className='button button--flex button__cat'
        onClick={() =>
          alert(
            'The Feline on my shoulder is called Kutush. Kutush is a fiesty girl'
          )
        }
      >
        Say Hello
        <i className='bx bxs-cat send-icon'></i>
        {/*<img className='send-icon' src={Send} alt='' />*/}
      </button>
    </div>
  );
};

export default Data;
