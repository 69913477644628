import React from 'react';
import './gratitude.css';
import { Data } from './Data';

import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';

const Gratitude = () => {
  return (
    <section className='gratitude container section'>
      <h2 className='section__title'>Thanks To....</h2>
      <span className='section__subtitle'>
        People whose effort and teaching skills have contributed to my amassed
        knowledge
      </span>

      <Swiper
        className='gratitude__container'
        loop={true}
        grabCursor={true}
        // slidesPerView={2}
        spaceBetween={24}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          576: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 48,
          },
        }}
        modules={[Pagination]}
      >
        {Data.map(({ id, name, channelPhoto, channelLink, type }) => {
          return (
            <SwiperSlide className='gratitude__card' key={id}>
              <img src={channelPhoto} alt='' className='gratitude__img' />
              <h3 className='gratitude__name'>{name}</h3>
              <h5 className='channel__type'>{type}</h5>
              <a
                href={channelLink}
                target='_blank'
                rel='noreferrer'
                className='channel__link'
              >
                <i className='uil uil-youtube'></i>
              </a>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
};

export default Gratitude;
