import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import './contact.css';
// import Send from '../../assets/send.svg';

const Contact = () => {
  const formRef = useRef();

  const sendEmail = e => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_xna6saj',
        'template_vqqu2mp',
        formRef.current,
        'Ei9hLqUFK2wsmoboE'
      )
      .then(
        result => {
          console.log(result.text);
        },
        error => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <section className='contact section' id='contact'>
      <h2 className='section__title'>Get in Touch</h2>
      <span className='section__subtitle'>Contact Me</span>

      <div className='contact__container container grid'>
        <div className='contact__content'>
          <h3 className='contact__title'>Hit me up</h3>
          <div className='contact__info'>
            {/*Email*/}
            <div className='contact__card'>
              <i className='bx bx-mail-send contact__card-icon'></i>
              <h3 className='contact__card-title'>Email</h3>
              <span className='contact__card-data'>
                nafisahmed@iut-dhaka.edu
              </span>
              <a
                href='mailto:nafisahmed@iut-dhaka.edu'
                className='contact__button'
              >
                Write me{' '}
                <i className='bx bx-right-arrow-alt contact__button-icon'></i>
              </a>
            </div>
          </div>
        </div>

        <div className='contact__content'>
          <h3 className='contact__title'>Tell me about your idea</h3>
          <form ref={formRef} onSubmit={sendEmail} className='contact__form'>
            <div className='contact__form-div'>
              <label className='contact__form-tag'>Name</label>
              <input
                type='text'
                name='name'
                className='contact__form-input'
                placeholder='Insert your name'
              />
            </div>
            <div className='contact__form-div'>
              <label className='contact__form-tag'>Mail</label>
              <input
                type='email'
                name='email'
                className='contact__form-input'
                placeholder='Insert your email address'
              />
            </div>
            <div className='contact__form-div contact__form-area'>
              <label className='contact__form-tag '>Project</label>
              <textarea
                name='project'
                cols='30'
                rows='10'
                className='contact__form-input'
                placeholder='Write about your project'
              ></textarea>
            </div>
            <a
              draggable={false}
              href='#contact'
              className='button button--flex'
              onClick={sendEmail}
            >
              Send Message
              <i class='uil uil-telegram-alt icon'></i>
            </a>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
