import React from 'react';

const Social = () => {
  return (
    <div className='home__social'>
      <a
        draggable={false}
        href='https://github.com/nafisahmed42018'
        className='home__social-icon'
        target='_blank'
        //
        rel='noreferrer'
      >
        <i className='uil uil-github-alt'></i>
      </a>

      <a
        draggable={false}
        href='https://github.com/nafisahmed42018'
        className='home__social-icon'
        target='_blank'
        rel='noreferrer'
      >
        <i className='uil uil-linkedin'></i>
      </a>

      <a
        draggable={false}
        href='https://github.com/nafisahmed42018'
        className='home__social-icon'
        target='_blank'
        rel='noreferrer'
      >
        <i className='uil uil-blogger-alt'></i>
      </a>
    </div>
  );
};

export default Social;
