import React, { useState } from 'react';
import './services.css';

const Services = () => {
  const [toggleState, setToggleState] = useState(0);

  const toggleService = index => {
    setToggleState(index);
  };
  return (
    <section className='services section' id='services'>
      <h2 className='section__title'>Services</h2>
      <span className='section__subtitle'>
        What I would be able to provide . . .
      </span>

      <div className='services__container container grid'>
        <div className='services__content'>
          <div>
            <i className='bx bx-globe services__icon'></i>
            <h3 className='services__title'>
              Web <br />
              Development
            </h3>
          </div>

          <span className='services__button' onClick={() => toggleService(1)}>
            View More
            <i className='uil uil-arrow-right services__button-icon'></i>
          </span>

          <div
            className={
              toggleState === 1
                ? 'services__modal active-modal'
                : 'services__modal'
            }
          >
            <div className='services__modal-content'>
              <i
                className='uil uil-times services__modal-close'
                onClick={() => toggleService(0)}
              ></i>
              <h3 className='services__modal-title'>Web Developer</h3>
              <p className='services__modal-description'>
                Web development is a wide domain area, where services vary from
                client side user interface design, server configuration and
                maintenance, database management, hosting service, search engine
                optimization. Among them i have experience in -
              </p>

              <ul className='services__modal-services grid'>
                <li className='services__modal-service'>
                  <i className='uil uil-check-circle services__modal-icon'></i>
                  <p className='services__modal-info'>
                    Responsive User Interface Design
                  </p>
                </li>
                <li className='services__modal-service'>
                  <i className='uil uil-check-circle services__modal-icon'></i>
                  <p className='services__modal-info'>
                    Ability to work with front-end javascript frameworks
                  </p>
                </li>
                <li className='services__modal-service'>
                  <i className='uil uil-check-circle services__modal-icon'></i>
                  <p className='services__modal-info'>Creating RESTful APIs</p>
                </li>
                <li className='services__modal-service'>
                  <i className='uil uil-check-circle services__modal-icon'></i>
                  <p className='services__modal-info'>
                    Creating Database Models
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className='services__content'>
          <div>
            <i className='bx bxl-flutter services__icon'></i>
            <h3 className='services__title'>
              Flutter <br />
              Development
            </h3>
          </div>

          <span className='services__button' onClick={() => toggleService(2)}>
            View More
            <i className='uil uil-arrow-right services__button-icon'></i>
          </span>

          <div
            className={
              toggleState === 2
                ? 'services__modal active-modal'
                : 'services__modal'
            }
          >
            <div className='services__modal-content'>
              <i
                className='uil uil-times services__modal-close'
                onClick={() => toggleService(0)}
              ></i>
              <h3 className='services__modal-title'>Flutter Developer</h3>
              <p className='services__modal-description'>
                I have moderate knowledge in flutter in terms of state
                management and creating UI. Picked it up because of school
                projects; styling is a bit hard for me compared to CSS. Although
                i can -
              </p>

              <ul className='services__modal-services grid'>
                <li className='services__modal-service'>
                  <i className='uil uil-check-circle services__modal-icon'></i>
                  <p className='services__modal-info'>
                    Create Intuitive Design
                  </p>
                </li>
                <li className='services__modal-service'>
                  <i className='uil uil-check-circle services__modal-icon'></i>
                  <p className='services__modal-info'>
                    Create Multifunctional Application
                  </p>
                </li>
                <li className='services__modal-service'>
                  <i className='uil uil-check-circle services__modal-icon'></i>
                  <p className='services__modal-info'>
                    Build Android, iOS and Web Application
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className='services__content'>
          <div>
            <i className='bx bxl-graphql services__icon'></i>
            <h3 className='services__title'>
              Blockchain <br /> Development
            </h3>
          </div>

          <span className='services__button' onClick={() => toggleService(3)}>
            View More
            <i className='uil uil-arrow-right services__button-icon'></i>
          </span>

          <div
            className={
              toggleState === 3
                ? 'services__modal active-modal'
                : 'services__modal'
            }
          >
            <div className='services__modal-content'>
              <i
                className='uil uil-times services__modal-close'
                onClick={() => toggleService(0)}
              ></i>
              <h3 className='services__modal-title'>Blockchain Development</h3>
              <p className='services__modal-description'>
                Blockchain is a comparatively new technology in the web, but it
                is being adopted into development from its conceptual in the
                recent years. I did my thesis project in it and spend
                significant amount time developing web3 applications
              </p>

              <ul className='services__modal-services grid'>
                <li className='services__modal-service'>
                  <i className='uil uil-check-circle services__modal-icon'></i>
                  <p className='services__modal-info'>
                    Develop Smart Contract in Solidity
                  </p>
                </li>
                <li className='services__modal-service'>
                  <i className='uil uil-check-circle services__modal-icon'></i>
                  <p className='services__modal-info'>Host Blockchain Node</p>
                </li>
                <li className='services__modal-service'>
                  <i className='uil uil-check-circle services__modal-icon'></i>
                  <p className='services__modal-info'>
                    Host Interplantery File System Storage
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
