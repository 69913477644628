import React from 'react';

const Backend = () => {
  return (
    <div className='skills__content'>
      <h3 className='skills__title'>Backend</h3>

      <div className='skills__box'>
        <div className='skills__group'>
          <div className='skills__data'>
            <i className='bx bx-badge-check'></i>

            <div>
              <h3 className='skills__name'>NodeJS</h3>
              <span className='skills__level'>Beginner</span>
            </div>
          </div>

          <div className='skills__data'>
            <i className='bx bx-badge-check'></i>

            <div>
              <h3 className='skills__name'>Laravel</h3>
              <span className='skills__level'>Beginner</span>
            </div>
          </div>
          <div className='skills__data'>
            <i className='bx bx-badge-check'></i>

            <div>
              <h3 className='skills__name'>SocketIO</h3>
              <span className='skills__level'>Beginner</span>
            </div>
          </div>
        </div>
        <div className='skills__group'>
          <div className='skills__data'>
            <i className='bx bx-badge-check'></i>

            <div>
              <h3 className='skills__name'>MySQL</h3>
              <span className='skills__level'>Intermediate</span>
            </div>
          </div>
          <div className='skills__data'>
            <i className='bx bx-badge-check'></i>

            <div>
              <h3 className='skills__name'>MongoDB</h3>
              <span className='skills__level'>Intermediate</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Backend;
