import React from 'react';

const ProjectItems = ({ project }) => {
  console.log(project);
  return (
    <div className='project__card' key={project.id}>
      <img
        src={project.image}
        alt=''
        className='project__thumbnail'
        draggable={false}
      />
      <h3 className='project__title'>{project.title}</h3>
      <div className='project__button'>
        <a
          draggable={false}
          href={project.demoLink}
          target='_blank'
          rel='noopener noreferrer'
          className='link__button'
        >
          <i className='uil uil-presentation-play link__icon'></i>Demo
        </a>
        <a
          draggable={false}
          href={project.demoLink}
          target='_blank'
          rel='noopener noreferrer'
          className='link__button'
        >
          <i class='uil uil-github-alt link__icon'></i>Github
        </a>
      </div>
      <div className='tag__container'>
        {project.category.map(item => {
          return <span className='tag__name'>{item}</span>;
        })}
      </div>
    </div>
  );
};

export default ProjectItems;
