import React from 'react';

const Info = () => {
  return (
    <div className='about__info grid'>
      <div className='about__box'>
        <i className='about__icon bx bxs-award'></i>
        <h3 className='about__title'>Professional Experience</h3>
        <span className='about__subtitle'>5 months</span>
      </div>
      <div className='about__box'>
        <i className='about__icon bx bx-award'></i>
        <h3 className='about__title'>On-hand Experience</h3>
        <span className='about__subtitle'>3 years</span>
      </div>
      <div className='about__box'>
        <i className='about__icon bx bx-briefcase-alt'></i>
        <h3 className='about__title alter'>Projects Completed</h3>
        <span className='about__subtitle'>~ 20 Projects</span>
      </div>
    </div>
  );
};

export default Info;
