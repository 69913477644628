export const projectData = [
  {
    id: 1,
    image: require('../../assets/websites/react_music.PNG'),
    title: 'Harmony Player',
    type: 'Web App',
    category: ['react', 'web', 'front-end'],
    gitLink: 'https://github.com/nafisahmed42018',
    demoLink: 'https://react-harmony-player.netlify.app/',
  },
  {
    id: 2,
    image: require('../../assets/websites/kanban_board.PNG'),
    title: 'Kanban Board',
    type: 'Web App',
    category: ['vanillaJS', 'web'],
    gitLink: 'https://github.com/nafisahmed42018',
    demoLink: 'https://nafis-kanban.netlify.app/',
  },
  {
    id: 3,
    image: require('../../assets/websites/vanilla_player.PNG'),
    title: 'Vanilla Player',
    type: 'Web App',
    category: ['vanillaJS', 'web'],
    gitLink: 'https://github.com/nafisahmed42018',
    demoLink: 'https://vanilla-musicplayer.netlify.app/',
  },
  {
    id: 4,
    image: require('../../assets/websites/hangman.PNG'),
    title: 'Hangman',
    type: 'Web App',
    category: ['vanillaJS', 'web'],
    gitLink: 'https://github.com/nafisahmed42018',
    demoLink: 'https://hangman-vanilla.netlify.app/',
  },
  {
    id: 5,
    image: require('../../assets/websites/dxball.PNG'),
    title: 'Dx Ball',
    type: 'Web App',
    category: ['vanillaJS', 'web'],
    gitLink: 'https://github.com/nafisahmed42018',
    demoLink: 'https://nafis-dxball.netlify.app/',
  },

  {
    id: 6,
    image: require('../../assets/websites/ping_pong.PNG'),
    title: 'Ping Pong',
    type: 'Web App',
    category: ['vanillaJS', 'web'],
    gitLink: 'https://github.com/nafisahmed42018',
    demoLink: 'https://nafis-pong.netlify.app/',
  },
  {
    id: 7,
    image: require('../../assets/websites/paint_clone.PNG'),
    title: 'Paint Clone',
    type: 'Web App',
    category: ['vanillaJS', 'web'],
    gitLink: 'https://github.com/nafisahmed42018',
    demoLink: 'https://nafis-paint.netlify.app/',
  },
  {
    id: 8,
    image: require('../../assets/websites/bon_voyage.PNG'),
    title: 'Bon Voyage',
    type: 'Design',
    category: ['vanillaJS', 'UI'],
    gitLink: 'https://github.com/nafisahmed42018',
    demoLink: 'https://travelsite-landingpage.netlify.app/',
  },
  {
    id: 9,
    image: require('../../assets/websites/kenakata_cart.PNG'),
    title: 'Kenakata',
    type: 'Web App',
    category: ['react', 'web', 'full-stack'],
    gitLink: 'https://github.com/nafisahmed42018',
    demoLink: 'https://kenakata-fullstack.netlify.app/',
  },
  {
    id: 10,
    image: require('../../assets/websites/cinephile_ui.PNG'),
    title: 'Cinephile',
    type: 'Design',
    category: ['vanillaJS', 'UI'],
    gitLink: 'https://github.com/nafisahmed42018',
    demoLink: 'https://cinephile-ui.netlify.app/',
  },
];

export const projectType = [
  {
    name: 'All',
  },
  {
    name: 'Web App',
  },
  {
    name: 'Design',
  },
];
