export const Data = [
  {
    id: 1,
    name: 'developedbyed',
    channelPhoto: 'https://yt3.googleusercontent.com/JfcqVinxsIc8dYsPIrp6IBDRMBb420BqVd2bzS9wqcMWBTzmnjUJ74Q0VByY1gUA8p-AYZQzxQ=s176-c-k-c0x00ffffff-no-rj',
    channelLink: 'https://www.youtube.com/@developedbyed',
    type: 'Web Design',
  },
  {
    id: 2,
    name: 'Traversy Media',
    channelPhoto: 'https://yt3.googleusercontent.com/ytc/AMLnZu-fB-c8gczS81r-VBWgzndcSZgd1zGA2QcYfACHMg=s176-c-k-c0x00ffffff-no-rj',
    channelLink: 'https://www.youtube.com/@TraversyMedia',
    type: 'Web Development',
  },
  {
    id: 3,
    name: 'JavaScript Mastery',
    channelPhoto: 'https://yt3.googleusercontent.com/wg1TITEoPfxvBGfzuqWyt3bqm_qu35ZhMswUv3feetU3xNX_6wsAXZF40OlPIgY4TmqbqCmAZ1U=s176-c-k-c0x00ffffff-no-rj',
    channelLink: 'https://www.youtube.com/@javascriptmastery',
    type: 'Web Design & Development',
  },
  {
    id: 4,
    name: 'Web Dev Simplified',
    channelPhoto: 'https://yt3.googleusercontent.com/ytc/AMLnZu-PjRc81p0qP-ZPWxv8aY4k-4gFRzGEYs3HWbIQ=s176-c-k-c0x00ffffff-no-rj',
    channelLink: 'https://www.youtube.com/@WebDevSimplified',
    type: 'Web Development',
  },
  {
    id: 5,
    name: 'Johannes Milke',
    channelPhoto: 'https://yt3.googleusercontent.com/QElMlmVmOkZ-d8p1oGlU8RX_RO1GIa3QF2mFEoZoMHZJJaRZGmMbB0fllrGim7J9-aQSqjYE9w=s176-c-k-c0x00ffffff-no-rj',
    channelLink: 'https://www.youtube.com/@JohannesMilke',
    type: 'Flutter',
  },
];
